
/* agreement page*/
.agreement_page {
  min-height: 100vh;
  padding-top: 30px;
  padding-bottom: 140px;
  margin: auto;
  color: black;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.agreement_page h1 {
  margin: 20px 0;
  text-align: center;
  font-size: 51px;
  font-weight: 800;
}
.agreement_page h3 {
  margin: 30px 0 15px 0;
  font-size: 24px;
  line-height: 26px;
  font-weight: 800;
  text-align: left;
}
.agreement_page p, .agreement_page ul {
  margin: 15px auto;
  font-size: 16px;
  line-height: 18px;
  text-align: justify;
}
.agreement_page li {
  margin: 8px 0;
}
.agreement_page b {
  font-weight: bold;
}
.argeement_content {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media(min-width: 768px) {
  .argeement_content {
    width: 700px;
  }
}
@media(min-width: 992px) {
  .argeement_content {
    width: 900px;
  }
}
@media(min-width: 1200px) {
  .argeement_content {
    width: 1100px;
  }
}
footer .agreement {
  color: #ffffff;
  text-decoration: underline;
}
