button:focus {
    outline: none;
}
.flag-select {
    position: relative;
    display: inline-block;
    vertical-align: inherit;
    text-align: left;
    margin-top: -8px;
    background-color: #FFFFFF;
    border: 1px solid #F4F7F7;
    border-radius: 5px;
    font-size: 16px;
    width: 200px;
}
.flag-select:hover {
    /*box-shadow: 1px 14px 17px 1px rgba(13, 13, 13, 0.02);*/
    box-shadow: 0 0 20px 5px rgba(13, 13, 13, 0.02);
}

.flag-select:focus {
    border: 1px solid #000;
}

.flag-select__btn {
    padding-left: 3px;
    color: black;
    border: none;
    background: transparent;
    white-space: nowrap;
    width: 100%;
    text-align: left;
    padding-right: 40px;
    font-family: Roboto, sans-serif;
}

.flag-select__btn::after {
    border-top: 5px solid #999;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    width: 0;
    height: 0;
    content: '';
    display: block;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 15px;
    top: 50%;
    transform-origin: 50% 50%;
    transform: rotate(0);
    transition: all .15s ease-in-out;
}

.flag-select .flag-select__btn[aria-expanded="true"]:after {
    transform: rotate(-180deg);
}

.flag-select__option {
    cursor: pointer;
    padding: 0 8px 3px 8px;
    margin: 4px 0;
    white-space: nowrap
}

.flag-select__options {
    position: absolute;
    width: 200px;
    z-index: 9;
    border: 1px solid #F4F7F7;
    box-shadow: 0 5px 20px 3px rgba(13, 13, 13, 0.02);
    border-radius: 5px;
    background: #fff;
    max-height: 180px;
    overflow: auto;
    margin-top: 4px;
    transform-origin: 50% 0;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
}

.flag-select__options.to--left {
    right: 10px
}

.flag-select__option--placeholder {
    height: inherit;
    width: inherit;
    display: inline-block;
    vertical-align: middle
}

.flag-select__option:not(.flag-select__option--placeholder):hover, .flag-select__option:not(.flag-select__option--placeholder):focus {
    outline: none;
    background: #eaeaea
}

.flag-select__option.has-label {
    padding: 3px 20px 3px 8px
}

.flag-select__option.has-label:first-of-type {
    margin-top: 0;
}

.flag-select__option.has-label:last-of-type {
    margin-bottom: 0;
    padding-bottom: 8px;
}

.flag-select__option__label {
    font-size: 16px;
    position: relative;
    padding-left: 9px;
    font-weight: 200;
}

.flag-select__option__icon {
    width: 20px;
    height: 20px;
    position: relative;
    top: 0.3em;
    margin-right: 5px;
}

.flag-select .filterBox {
    width: 100%
}

.flag-select .filterBox input {
    width: 90%;
    margin: 0 4%
}

.flag-select .filterBox input:focus {
    outline: none
}

.flag-select .hidden {
    display: none
}

.flag-select .no--focus {
    pointer-events: none
}
